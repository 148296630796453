.container {
  /* background: linear-gradient(180deg, var(--dark-accent) 35%, var(--mid-accent) 35%); */
  background-color: var(--dark-accent);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary-text-colour);
  border-color: var(--border-colour);
}

.authWindow {
  background-color: var(--modal-background);
  max-width: 720px;
  width: 90%;
  padding: 1.6rem;
  border-radius: 3px;
  box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.6);
}

.logo {
  padding: 2rem;
  max-width: 500px;
  width: 67%;
  display: block;
  /* margin-top: calc(35vh - 300px); */
}

.logo img {
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
}

.formAction {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 0.8rem;
}

@media only screen and (max-width: 500px) {
  .logo {
    margin-top: 0;
  }

  .container {
    background: linear-gradient(
      180deg,
      var(--dark-accent) 30%,
      var(--mid-accent) 30%
    );
  }
}

.copy {
  color: var(--light-text-colour);
  padding-top: 1rem;
  font-size: 0.875rem;
}

.sentEmail span {
  font-weight: bold;
}

.errorField {
  border-color: var(--action-negative) !important;
}

.errorLine {
  color: var(--action-negative);
}

.errors {
  background-color: var(--action-negative);
  color: white;
  padding: 0.4rem;
  border-radius: 3px;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.info {
  background-color: var(--action-warning);
  color: white;
  padding: 0.4rem;
  border-radius: 3px;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}
