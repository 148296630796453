.marketing__newsletter {
  padding: calc(55px * var(--height-increment)) 0;
  background: var(--brand-primary);
  font-size: 2.25rem;
}

.marketing__newsletter_bluedivider {
  border-top: 9px solid #033359;
}

.marketing__newsletter_columns {
  display: grid;
  grid-template-columns: auto 0.965fr 0.965fr 0.375fr;
  column-gap: 32px;
  align-items: center;
  max-width: calc(1375px * var(--width-increment));
  margin: 0 auto;
}

.marketing__newsletter label {
  color: #fff;
}

.marketing__newsletter input {
  display: block;
  width: 100%;
  padding: 1.5rem;
  background: #fff;
  border: 2px solid transparent;
  border-radius: 99rem;
  outline: none;
  font-size: 1.275rem;
  font-weight: normal;
  font-family: inherit;
}

.marketing__newsletter input:focus {
  border-color: var(--brand-secondary);
}

.marketing__newsletter button {
  display: block;
  width: 100%;
  padding: 1.5rem 1.25rem !important;
  background: var(--brand-secondary);
  color: #fff;
  border: 0;
  border-radius: 99rem;
  outline: none;
  font-size: 1.375rem;
  font-family: inherit;
  cursor: pointer;
}

@media screen and (max-width: 980px) {
  .marketing__newsletter_columns {
    grid-template-columns: 1fr;
  }

  .marketing__newsletter {
    font-size: 1rem;
  }

  .marketing__newsletter label {
    display: block;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.25rem;
  }

  .marketing__newsletter input,
  .marketing__newsletter button {
    margin-bottom: 1rem;
    padding: 1rem;
    font-size: inherit;
  }
}

.notice {
  text-align: center;
  padding-bottom: 1rem;
  color: var(--brand-secondary);
  font-size: 2.2rem;
}
