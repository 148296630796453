.marketing__button_container {
  padding: 100px 0 0;
  text-align: center;
}

.button {
  display: inline-block;
  padding: 1rem 3.5rem !important;
  border-radius: 99rem;
  background: var(--brand-primary-dark);
  color: #fff;
  font-size: 1.625rem;
  cursor: pointer;
}

.button_primary {
  background: var(--brand-primary-dark) !important;
  color: #fff;
}

.button:hover {
  background-color: var(--brand-blue-highlight);
  color: #fff;
}

.button_white {
  background: #fff;
  color: var(--brand-secondary);
}

.button_blue {
  background: var(--brand-secondary);
  color: #fff;
}

.button_small {
  font-size: 1rem;
}

.button_wide {
  padding: 1rem 5.25rem !important;
}

@media screen and (max-width: 1400px) {
  .button {
    font-size: 1rem;
  }

  .marketing__button_container {
    padding: 50px 0;
  }
}

@media screen and (max-width: 980px) {
  .button,
  .button_wide {
    padding: 0.75rem 2.5rem !important;
    width: auto !important;
  }
}
